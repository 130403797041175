import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import dynamic from 'next/dynamic';
const Image = dynamic(() => import('next/image'));
import classnames from 'classnames';
import Link from 'next/link';

// import { WagmiConnect } from '../../components/common/WagmiConnect';
const ConnectWallet = dynamic(() => import('../../components/auth/ConnectWallet'));
const Hamburger = dynamic(() => import('../../components/common/Hamburger'));

import { IOS_NOTCH_MARGIN, HEADER_HEIGHT } from '../../utils/variables';

import styles from '../../sass/components/common/TheHeader.module.scss';

const mapStoreToProps = (store) => {
  return {
    unreadEndorsements: store.data.unreadEndorsements,
    hasNotch: store.setup?.deviceSettings?.hasNotch,
    isMobile: store.setup?.isMobile,
  };
};

class TheHeader extends React.PureComponent {

  constructor(props) {
    super(props);
    this.state = {
      isSticky: false,
    };
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', this.handleScroll);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const scrollPosition = document.documentElement.scrollTop;
    const isSticky = this.state.isSticky;
    if (scrollPosition > 25 && !isSticky) {
      this.setState({ isSticky: true });
    } else if (scrollPosition < 25 && isSticky) {
      this.setState({ isSticky: false });
    }
  }

  getHeaderHeight = () => {
    const { hasNotch, isMobile, center } = this.props;

    let height = HEADER_HEIGHT;
    if (hasNotch) {
      height += IOS_NOTCH_MARGIN;
    }
    if (isMobile && center) {
      height += 20 + 50; //padding-top + center block height + padding-bottom
    }
    
    return height;
  }

  renderDefaultLeft = () => {
    return (
      <Link className={styles.logoLink} href='/'>
        <a>
          <Image
            src='/images/common/logo.svg'
            alt='PNTHN logo'
            width='74'
            height='16'
            priority={true}
          />
        </a>
      </Link>
    );
  }

  renderDefaultRight = () => {
    return (
      <>
        {this.props.isMobile ? null : <ConnectWallet />}
        {this.props.withoutNav ? null : <Hamburger unreadEndorsements={this.props.unreadEndorsements} />}
      </>
    );
  }

  render = () => {
    const { left, center, right, className, hasNotch } = this.props;
    const isSticky = this.state.isSticky;

    return (
      <div
        className={classnames({
          [styles.header]: true,
          [styles.withNotch]: hasNotch,
          [styles.isSticky]: isSticky,
          [className]: !!className,
        })}
        style={{ height: `${this.getHeaderHeight()}px`, marginBottom: `-${this.getHeaderHeight()}px` }}
      >
        <div
          className={classnames(styles.headerContainer, 'container full', {
            [styles.withCenter]: center
          })}
        >
          <div className={classnames(styles.block, styles.left)}>
            {left ? left : this.renderDefaultLeft()}
          </div>
          {center &&
          <div className={classnames(styles.block, styles.center)}>
            {center}
          </div>
          }
          <div className={classnames(styles.block, styles.right)}>
            {right ? right : this.renderDefaultRight()}
          </div>
        </div>
      </div>
    );
  }

}

export default connect(mapStoreToProps)(withRouter(TheHeader));
