import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import Head from 'next/head';
import Image from 'next/image';
import classnames from 'classnames';
import Link from 'next/link';

import TheHeader from '../components/common/TheHeader';
import ConnectWallet from '../components/auth/ConnectWallet';
import ProfileImage from '../components/common/ProfileImage';

import { triggerEvent, sendRequest } from '../utils/global';

import styles from '../sass/pages/Home.module.scss';

const mapStoreToProps = (store) => {
  return {
    user: store.data.user,
    wizardStep: store.data.wizardStep,
    isMobile: store.setup?.isMobile,
  };
};

const USERS = [
  {
    "_id": "62f514d2c28e1cda7d7af86b",
    "name": "Zeneca",
    "username": "zeneca",
    "summary": "Founder and CEO of ZenAcademy and The 333 Club.",
    "bio": null,
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/7cf54b2b-dfec-48e5-a782-063f4d5c7d00/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62f546a2c28e1cda7d7af919",
    "name": "chriswallace",
    "username": "designer",
    "summary": "Founder of UltraDAO and Woodies.",
    "bio": "I am a creator, artist, and collector.",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/8799269d-6f24-4672-12c3-4d3941823000/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62f265452af69b1e2a1b6107",
    "name": "nggg.eth",
    "username": "nggg",
    "summary": "Server Admin / Head Moderator / Community Manager in Discord servers specialising in NFT projects",
    "bio": "Full time NFT/web3 I help people to setup & manage Discord servers; nggg.eth; Azuki 7826; CloneX 10441",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/7e5a6aec-91b4-4902-de18-e4e358ce6d00/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62ed449b2af69b1e2a1b60f4",
    "name": "emileevee",
    "username": "emileevee",
    "summary": "Marketing + Lore at ZenAcademy",
    "bio": "🍄⛺️ 🧀🦎 🦋 ♉️ (she/her)",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/1393e1ee-ce47-46e4-a691-7a9638f35600/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62f51c7cc28e1cda7d7af87f",
    "name": "Brennan",
    "username": "Brennan",
    "summary": "Director of Strategy and Operations at WGMI.io",
    "bio": "I am the Director of Strategy and Operations for Web3 startup WGMI.io.  I am an investor in the Web3 and NFT space through equity investments as an Accredited Investor into companies in the space (Dapper Labs, OnChain Studios, Fan Controlled Football, etc.) as well as a collector of many NFT projects\n\nFor 20+ years I provided consulting services to businesses of all sizes and scope, (via employment with Arthur Andersen, Accenture, Deloitte, etc.)",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/3cfd4ddd-1907-4fe7-59dc-e5dabc8a9500/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62f520d8c28e1cda7d7af8b0",
    "name": "elisafelisa",
    "username": "elisafelisa",
    "summary": "marketer & 'artist' traveler",
    "bio": "passionate about branding, communities, blockchain, Web3 & NFTs.\nmarketer irl who love travel and make sketches journals.",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/fea45901-b416-4ec6-a14b-900bb9cd1e00/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62f29b05d02b69c065419974",
    "name": "ianseaton",
    "username": "ianseaton",
    "summary": "Veteran Esports Events & Marketing Specialist.",
    "bio": "I am an NZ esports veteran from 2013 that has worked with brands like Red Bull New Zealand, MSI ANZ (Laptops) & many other international brands.\n\nMy experience is specialized in Esports events, marketing, talent & community management. \nI am apt in brand development and well studied on gaming/ web3 integration. I am particularly interested in it's future in modern society & how society will in turn react to it, bolstered by my bachelors in sociology.",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/519e8149-e6eb-49b8-accb-be247e25c300/w500",
    "is_genesis_holder": false
  },
  {
    "_id": "62f51cfac28e1cda7d7af88b",
    "name": "Tofer",
    "username": "tofer",
    "summary": "NFT Collector",
    "bio": "Lives in NYC",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/b9303854-ade1-43e2-6beb-57046f9cf700/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62f5258a4c51cab42aaaa22a",
    "name": "demian",
    "username": "demian",
    "summary": "Co-founder at @pfpedia creator & collector NFTs, web3, tech, art, music",
    "bio": null,
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/955da2ef-1225-4e10-755a-5fcc030c3000/w500",
    "is_genesis_holder": false
  },
  {
    "_id": "62f50c534c51cab42aaaa1d6",
    "name": "momo",
    "username": "momo",
    "summary": "Howdy, I'm momo.",
    "bio": "I like to build things and collect JPGs.",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/7ede8047-80fe-4983-4244-f87c838d4a00/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62e59651482429b28611abf6",
    "name": "NFDoggo",
    "username": "NFDoggo",
    "summary": "Dad, Dog",
    "bio": "Dad, startup attorney, COO at stealth web3 project",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/6c789261-83b9-4061-ed15-bb285325f600/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62d0fe417d3e9fd58fc46138",
    "name": "Tweetfreq",
    "username": "Tweetfreq",
    "summary": "NFT Advisor for Neon Pantheon | Community Manager/Advisor for Salty Pirate Crew | Mod for Magma NFT",
    "bio": "I got a relatively late start in Crypto and started seriously looking into nfts in July of last year. I learned as much as I could from as many different people I could, soaking up all the information I could get my hands on. \n\nI went from mod to community manager/advisor for 2 different projects; Neon Pantheon and Salty Pirate Crew. Oh and just became a mod for Magma.",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/cc114cab-ca53-469d-4c27-d8771b483000/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62e54b8286c8924ec7be512f",
    "name": "hypersound",
    "username": "hypersound",
    "summary": "Product, Music Tech & Biz",
    "bio": "Product builder by day, NFT collector by night. Currently leading product team at Gaspack.xyz. Member of The 333 Club, Neon Pantheon Genesis.",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/cd44ab5c-2fed-43dd-f263-043dfd483b00/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62dabfd3716c397b0e7968d4",
    "name": "Lory",
    "username": "lory",
    "summary": "Hi, I'm Lory!",
    "bio": "Mod at Neon Pantheon",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/6402e27f-8f47-4180-0e61-1c2f9d038a00/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62eca3d0b7f788277051450b",
    "name": "myitchyfinger",
    "username": "myitchyfinger",
    "summary": "I visualize things in polygons.",
    "bio": "I’m a digital artist that specialise in 2D Polyart , crafted using a pentool in illustrator. \n\nI am also a full time Senior Digital Product Designer outside the web3 space.",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/ea349635-b7c3-43f0-da62-48be0b4f7100/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62e449e2482429b28611abe3",
    "name": "Babs",
    "username": "purplelotusbabs",
    "summary": "I love early mornings, meditation, deep conversations, finding beauty in everything, and supporting people as they grow.",
    "bio": "I'm taking my 15+ yrs of IRL work with individuals and groups to Web 3 as Purple Lotus Collective (PLC). I provide mediation for servers, individual meditation & holistic coaching, IRL or virtual retreats, and life collective groups.  \n--\nPLC was born out of my life's journey.  It's been a journey full of ups and downs.  Despite, or perhaps because of the ups and downs, I ultimately found her way to wholeness & love, light, hope, & joy.  Through PLC I share that with you.",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/6cc6d89b-24b6-4885-8a58-614ce2e31c00/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62e4362a86c8924ec7be5120",
    "name": "Rusty Moore",
    "username": "rusty",
    "summary": "Fitness Writer * Beer Connoisseur * NFT Collector",
    "bio": "I consider myself an early adopter. I love getting into new technology and trends before they become mainstream. \n\nI started a fitness blog in 2007 when blogs were a new thing. People who didn't understand the blogging shift missed out on an incredible way to get tons of free traffic. Many of us were able to quit our jobs within 1-2 years.\n\nNFTs are in a similar place. The public will catch on in about 3-4 years... but the biggest window of opportunity is from now to 2026.",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/3c1403c9-3597-441f-556c-8c30966cd400/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62d00ec9c81bd0ce45668e50",
    "name": "Danny Limanseta",
    "username": "vetigraf",
    "summary": "UX Designer, Artist and Co-founder",
    "bio": "Artist & Co-creator of Neon Pantheon and PNTHN. UX Designer/Art Director. Co-creator of Reroll. 333 Club member and Magma Pass holder.",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/cc406321-aebc-41d4-6b05-7523f071ab00/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62db126298f74a03d8b29840",
    "name": "viking.power",
    "username": "vikingpower",
    "summary": "Neon Pantheon Berserker",
    "bio": "Seattle, WA",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/ec52eb97-341b-4f0c-71b4-e4d1f6fa5700/w500",
    "is_genesis_holder": true
  },
  {
    "_id": "62dac715716c397b0e7968d6",
    "name": "Zabiusu",
    "username": "zabiusu",
    "summary": "23 y.o Architecture graduate who's been in the NFT space since August 2021",
    "bio": "Trying to be better and learn new things each day\nalways looking for new opprtunities\n\nModerator and Collab Manager\nCommunity Manager in the making",
    "image": "https://imagedelivery.net/gl54X6hy2WPSeOjeJhkeJw/55feb912-7ae9-44cb-b25f-0a8e3249e600/w500",
    "is_genesis_holder": true
  }
];

class HomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      showUsers: false,
    };

    this.usersListRef = React.createRef();
  }

  componentDidMount = () => {
    if (typeof window !== 'undefined') {
      this.setState({ showUsers: true });
    }
  }

  goToWizard = () => {
    const url = this.props.wizardStep || '/wizard/step/1';
    this.props.router.push(url);
  }

  goToMemberships = () => {
    const isGenesisHolder = this.props.user?.is_genesis_holder || this.props.user?.plan?.name === 'genesis';

    const url = isGenesisHolder && !this.props.wizardStep ? this.props.user?.username : 'membership';
    this.props.router.push(`/${url}`);
  }

  goToNeonpantheon = () => {
    window.open('https://neonpantheon.com', '_blank');
  }

  goToOpensea = () => {
    window.open('https://opensea.io/collection/neonpantheon', '_blank');
  }

  scrollUsersRight = () => {
    const cardWidth = 340;
    const currentOffset = this.usersListRef.current.scrollLeft;
    const cardsAtScreen = Math.floor(window.innerWidth / cardWidth);
    this.usersListRef.current.scrollTo({
      top: 0,
      left: Math.max(currentOffset -= cardWidth * cardsAtScreen, 0),
      behavior: 'smooth'
    });
  }

  scrollUsersLeft = () => {
    const cardWidth = 340;
    const maxScrollOffset = USERS.length * cardWidth - window.innerWidth;
    const currentOffset = this.usersListRef.current.scrollLeft;
    const cardsAtScreen = Math.floor(window.innerWidth / cardWidth);
    this.usersListRef.current.scrollTo({
      top: 0,
      left: Math.min(currentOffset += cardWidth * cardsAtScreen, maxScrollOffset),
      behavior: 'smooth'
    });
  }

  renderUserCard = (user) => {

    return (
      <div className={styles.userCard} key={user._id}>
        <div className={styles.userCardContent}>
          <div className={styles.box}>
            <div className={styles.image}>
              <Link href={`/${user.username}`}>
                <a>
                  <ProfileImage
                    url={user.image}
                    size='150'
                    name={user.name}
                    goldenBorder={user.is_genesis_holder}
                  />
                </a>
              </Link>
            </div>
            <Link href={`/${user.username}`}>
              <a>
                <div
                  className={classnames(styles.name, {
                    [styles.gen]: user.is_genesis_holder,
                    [styles.asc]: user.is_ascended_holder,
                  })}
                >
                  {user.name}{user.is_genesis_holder && <span>GEN</span>}{user.is_ascended_holder && <span>ASC</span>}
                </div>
              </a>
            </Link>
            <div className={styles.position}>
              {user.summary}
            </div>
            <div className={styles.summary}>
              {user.bio}
            </div>
          </div>
        </div>
      </div>
    );
  }

  render = () => {
    return (
      <>
        <Head>
          <title>PNTHN | The Professional Network for the Web3 space</title>
          <meta name='description' content='PNTHN helps you forge stronger connections, build your reputation and establish credibility in the Web3 space.' />
          <meta property='og:url' content='https://pnthn.xyz' />
          <meta property='og:title' content='PNTHN | The Professional Network for the Web3 space' />
          <meta property='og:image' content='https://pnthn.xyz/images/meta/card.png' />
          <meta property='og:description' content='PNTHN helps you forge stronger connections, build your reputation and establish credibility in the Web3 space.' />
          <meta name='twitter:title' content='PNTHN | The Professional Network for the Web3 space' />
          <meta name='twitter:description' content='PNTHN helps you forge stronger connections, build your reputation and establish credibility in the Web3 space.' />
          <meta name='twitter:image' content='https://pnthn.xyz/images/meta/card.png' />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='image' content='https://pnthn.xyz/images/meta/card.jpg' />
        </Head>

        <div className={styles.homePage}>
          <TheHeader />
          <div className={styles.heroBg}>
            <Image
              src={this.props.isMobile ? '/images/home/hero_mobile.jpg' : '/images/home/hero.png'}
              alt='PNTHN Hero background image'
              width={this.props.isMobile ? '390' : '1002'}
              height={this.props.isMobile ? '521' : '650.5'}
              objectFit={this.props.isMobile ? 'cover' : 'contain'}
              priority={true}
            />
          </div>
          <div className={styles.hero}>
            <div className='container small text-center'>
              <h1>The Professional Network for the Web3 space.</h1>
              <p>Forge stronger connections, build your reputation and establish credibility in the NFT space. It all starts with owning your PNTHN profile.</p>
              <button onClick={this.goToMemberships}>Claim your PNTHN profile</button>
            </div>
          </div>
          <div className={styles.usersSection}>
            <div className={styles.title}>Who’s already on the PNTHN</div>
            <div className={styles.usersList} ref={this.usersListRef}>
              {this.state.showUsers && USERS.map(this.renderUserCard)}
            </div>
            <div className={styles.controls}>
              <div
                className={styles.left}
                onClick={this.scrollUsersRight}
              />
              <div
                className={styles.right}
                onClick={this.scrollUsersLeft}
              />
            </div>
          </div>
          <div className={classnames('container', styles.body)}>
            <div className={styles.flex}>
              <div className={classnames(styles.block, styles.textblock)}>
                <h2>Flex with verified PFPs</h2>
                <p>All PNTHN profiles supports verified PFPs, so you’ll be able to flex them. <span onClick={this.goToOpensea}>Neon Pantheon holders</span> can upload up to 3 verified PFPs.</p>
              </div>
              <div className={styles.block}>
                <Image
                  src='/images/home/block-1.png'
                  alt='Block 1'
                  width='520'
                  height='520'
                  objectFit={this.props.isMobile ? 'cover' : 'contain'}
                  priority={true}
                />
              </div>
            </div>
            <div className={styles.flex}>
              <div className={styles.block}>
                <Image
                  src='/images/home/block-2.png'
                  alt='Block 2'
                  width='452'
                  height='452'
                  objectFit={this.props.isMobile ? 'cover' : 'contain'}
                  priority={true}
                />
              </div>
              <div className={classnames(styles.block, styles.textblock)}>
                <h2>Get endorsed</h2>
                <p>People that enjoyed working with you will be able to give you endorsements. You&#39;ll be able to do the same to people you have enjoyed working with as well.</p>
              </div>
            </div>
            <div className={styles.flex}>
              <div className={classnames(styles.block, styles.textblock)}>
                <h2>Share your Web3 journey</h2>
                <p>Worked on multiple Web3 or NFT projects? You&#39;ll be able to put them in one place right here.</p>
              </div>
              <div className={styles.block}>
                <Image
                  src='/images/home/block-3.png'
                  alt='Block 3'
                  width='520'
                  height='520'
                  objectFit={this.props.isMobile ? 'cover' : 'contain'}
                  priority={true}
                />
              </div>
            </div>
            <div className={styles.flex}>
              <div className={styles.block}>
                <Image
                  src='/images/home/block-4.png'
                  alt='Block 4'
                  width='520'
                  height='520'
                  objectFit={this.props.isMobile ? 'cover' : 'contain'}
                  priority={true}
                />
              </div>
              <div className={classnames(styles.block, styles.textblock)}>
                <h2>All your links in one place</h2>
                <p>Your PNTHN profile becomes the place to house all your links. This is the only profile page designed for the NFT space.</p>
              </div>
            </div>
          </div>
          <div className={styles.footer}>
            <div className={styles.doors}>
              <Image
                src='/images/home/footer_bg@2x.jpg'
                alt='PNTHN footer'
                width='612'
                height='502'
                objectFit={this.props.isMobile ? 'cover' : 'contain'}
                priority={true}
              />
            </div>
            <div className={classnames(styles.container, 'container')}>
              <div className={styles.title}>Reserve your PNTHN profile now</div>
              <p>PNTHN is currently in Beta testing phase. Anyone can create a PNTHN profile for free but <span onClick={this.goToOpensea}>Neon Pantheon Genesis Holders</span> & our Partner Communities will get lifetime access to additional features and perks.</p>
              <div className={styles.buttons}>
                <a href='https://neonpantheon.com' target='_blank' rel='noopener noreferrer'>
                  <button
                    className={styles.transparent}
                  >More about Neon Pantheon</button>
                </a>
                <a href='https://opensea.io/collection/neonpantheon' target='_blank' rel='noopener noreferrer'>
                  <button>View Genesis Collection</button>
                </a>
              </div>
              <div className={styles.divider}>
                <Image
                  src={this.props.isMobile ? '/images/home/footer_hero_mobile.png' : '/images/home/footer_hero.png'}
                  alt='PNTHN footer'
                  width={this.props.isMobile ? '390' : '950'}
                  height={this.props.isMobile ? '172.5' : '219'}
                  objectFit={this.props.isMobile ? 'cover' : 'contain'}
                  priority={true}
                />
              </div>
              <div className={styles.connect}>
                <div className={styles.text}>
                  Already a Neon Pantheon Genesis holder? Connect your wallet to get started.
                </div>
                <div>
                  <ConnectWallet accented />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default connect(mapStoreToProps)(withRouter(HomePage));