import React from 'react';
import Image from 'next/image';
import classnames from 'classnames';

import styles from '../../sass/components/common/ProfileImage.module.scss';

const ProfileImage = (props) => {
  const cdnList = ['imagedelivery.net', 'pbs.twimg.com', 'cdn.pnthn.com', 's3-ap-southeast-1.amazonaws.com'];
  const isFromCDN = cdnList.findIndex(cdn => props.url.indexOf(cdn) > -1) > -1;

  return (
    <div
      className={classnames(styles.profileImage, {
        [styles.goldenBorder]: props.goldenBorder,
        [styles[`w${props.size}`]]: true,
        [styles.clickable]: props.onClick,
      })}
      onClick={props.onClick}
    >
      <div
        className={classnames(styles.box, {
          [styles.main]: props.isMain
        })}
      >
        {
          /* eslint-disable */
          isFromCDN
            ? <Image
                src={props.url}
                alt={`${props.name}'s profile image`}
                width={props.size}
                height={props.size}
                quality='90'
              />
            : props.url.startsWith('<svg')
              ? <div className={styles.svgBox} dangerouslySetInnerHTML={{ __html: props.url }} />
              : <img
                  src={props.url}
                  alt={`${props.name}'s profile image`}
                  width={props.size}
                  height={props.size}
                />
          /* eslint-enable */
        }
      </div>
    </div>
  );
}

export default ProfileImage;